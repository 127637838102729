import React from 'react';
import './App.css';
import Card from './Components/Card/Card';
import Header from './Components/Header/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <Card />
    </div>
  );
}

export default App;
