import React from 'react';
import styles from './Card.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons';

const Card = () => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <div className={styles.cardHeadline}>
          <h1>Amazing Stone</h1>
          <h5 className={styles.slogan}>For all your countertop needs</h5>
        </div>
        <div className={styles.cardDetails}>
          <span className={styles.address}>
            <FontAwesomeIcon
              className={`${styles.icon} ${styles.locationIcon}`}
              icon={faLocationDot}
            />
            <p>170 Mace Street Ste D1</p>
          </span>
          <p className={styles.city}>Chula Vista, CA 91911</p>

          <span className={styles.phone}>
            <FontAwesomeIcon className={styles.icon} icon={faPhone} />
            <a href="tel:6193955449">619-395-5449</a>
          </span>
          <span>
            <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
            <a href="mailto:hello@amazingstonesd.com">
              hello@amazingstonesd.com
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Card;
